export function commons(){
	const vues = new Vue({
		el: '#site-wrapper',
		data: {
			modal: false,
			detail: null
		},
		methods: {
			works_modal: function(img){
				this.modal = true;
				this.detail = img;
			}
		}
	});
	
	const vshd = new Vue({
		el: '#site-header',
		data: {
			toggle: false,
			child: false
		}
	});
	
	const controller =  new ScrollMagic.Controller();
    new ScrollMagic.Scene({
        triggerElement: 'body',
        offset: window.innerHeight * 1
    })
    //.addIndicators()
    .setClassToggle('#site-header','active').addTo(controller)

}
import {commons} from './common';
import {firstview} from './index';
import {index} from './index';
import {works} from './works';
import {scrolls} from './scroll';

document.addEventListener('DOMContentLoaded', function() {
    commons();
    firstview();
    index();
	works();
	scrolls();
});

export function firstview(){
	if(document.getElementById('firstview')){
		const swipeOption = {
			loop: true,
			effect: 'fade',
			autoplay: {
				delay: 6000,
				disableOnInteraction: false,
			},
			speed: 2000,
		}

		const swiper = new Swiper('#firstview .swiper-container' , swipeOption);
	}
}

export function index(){
	if(document.getElementById('works-swipe')){
		const swipeOption = {
			loop: false,
			slidesPerView: 'auto',
			spaceBetween: 24,
			speed: 600,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-cu-next',
				prevEl: '.swiper-button-cu-prev',
			},
			autoplay: {
				delay: 2000,
				disableOnInteraction: false,
			},
			breakpoints: {
				768: {
				  slidesPerView: 1,
                  spaceBetween: 0
				}
			}
		}
		const swiper = new Swiper('#works-swipe' , swipeOption);
	}
}